@mixin no-items-available() {
  & > div:first-child {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
      width: 60% !important;
    }

    & .no-items__title {
      font-weight: bold;
    }
  }
}

.loginScreen {
  &__subtitle {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.7em;
    color: var(--ion-color-medium);
    margin-bottom: 20px;
  }

  &__title {
    margin-top: 5px;
  }

  &__region {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ion-row {
      &:first-child {
        flex: 1;
      }

      &:nth-of-type(2) {
        flex: 1;
      }

      &:nth-of-type(3) {
        flex: 2;
      }
    }
  }

  &__form {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    ion-row {
      &:first-child {
        flex: 1;
      }

      &:nth-of-type(2) {
        flex: 1;
      }

      &:nth-of-type(4) {
        flex: 2;
      }
    }
  }
}

img.login {
  width: 80% !important;
}

img.walkthrough {
  width: 80% !important;
  max-height: 280px !important;
  padding: 10% 0 10% 0 !important;
}

img.halfsize {
  width: 60% !important;
}

hr {
  margin-top: 3rem;
  max-width: 80%;
  background-color: var(--ion-color-primary);
}

@media screen and (min-width: 576px) {
  hr {
    max-width: 350px;
  }
}

.custom-dropdown {
  .popover-content {
    padding: 10px 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0 4px 16px 0;
  }

  &--login {
    .popover-content {
      transform: translateX(-50%);
      width: 90% !important;
    }
  }
}

.ios .custom-dropdown .popover-content {
  left: 50% !important;
}

ion-skeleton-text {
  line-height: 1;
  margin-bottom: 5px;
  margin-top: 5px;
}

.dashboardScreen {
  .no-items {
    @include no-items-available();

    &.no-items--height div {
      height: 100px;
    }
  }

  .upcomingShifts {
    margin: 20px 0;
  }
}

.text-center {
  text-align: center;
}

.text-sm {
  font-size: 0.875rem !important; /* 14px */
  line-height: 1.25rem !important; /* 20px */
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.shiftsScreen {
  .no-items {
    @include no-items-available();
  }
}

.shiftNoticeIcon {
  font-size: 1.5rem;
  position: relative;
  top: 6px;
  margin-right: .5rem;
}

.projectsScreen {
  .no-items {
    @include no-items-available();
  }

  ion-slides {
    .swiper-wrapper {
      height: auto !important;
    }
  }
}

.firstColumn {
  padding-top: 0px;
  padding-bottom: 0px;
}

.secondColumn {
  padding-top: 0px;
}

.cardImg {
  display: block;
}

.projectDetailScreen {
  .no-items {
    @include no-items-available();
  }

  ion-list {
    width: 100%;
  }
}

.bold-text {
  font-weight: bold;
}

.bottomToast {
  transform: translateY(-60px) !important;
}

.validationErrorMsg {
  text-align: left;
  display: block;
}

.ripple-parent {
  position: relative;
  overflow: hidden;
}

.shiftDescription {
  font-weight: normal;
  font-size: 14px;
}

ion-slides {
  height: 100%;
}

.slideIcons {
  font-size: 24px;
}

.copyIcon {
  cursor: pointer;
}

img.logo,
.logo {
  max-width: 160px !important;
  content: url('../../public/assets/images/servenow-light.svg');
}

.dark img.logo,
.dark .logo {
  content: url('../../public/assets/images/servenow-dark.svg');
}

.logoStc {
  max-width: 160px !important;
  content: url('../../public/assets/images/stc-logo-light.svg');
}

.dark .logoStc {
  content: url('../../public/assets/images/stc-logo-dark.svg');
}

.stcValues {
  content: url('../../public/assets/images/stc-values-light.svg');
}

.dark .stcValues {
  content: url('../../public/assets/images/stc-values-dark.svg');
}

.swiper-slide {
  display: block;
}

ion-item {
  --inner-padding-end: 0;
  --padding-start: 0;
  width: 100% !important;
}

ion-item.profile {
  --min-height: 0;
  --padding-bottom: 5px;
  --padding-top: 5px;
}

ion-chip {
  font-size: 0.75em;
}

.thumbnail {
  object-fit: cover;
  aspect-ratio: 1 / 1;
  max-width: 70px;
  max-height: 70px;
  border-radius: 10px;

  &::part(image) {
    border-radius: 10px;
  }
}

ion-thumbnail {
  --size: 70px;
  --border-radius: 10px;
}

ion-content {
  --padding-bottom: 20px !important;
}

ion-card {
  margin-bottom: 0 !important;
  @media only screen and (min-width: 576px) {
    &.ios {
      margin-bottom: 16px !important;
    }
    &.md {
      margin-bottom: 6px !important;
    }
  }

  ion-item::part(native) {
    background: transparent;
  }

  ion-card-title {
    font-size: 1.1rem;
  }

  ion-card-subtitle {
    font-weight: normal;
  }
}

ion-datetime {
  --background: transparent;
  --background-rgb: 255, 255, 255;
}

.dark ion-datetime {
  --background-rgb: 0, 0, 0;
}

// .card-content-ios {
//   padding-inline-start: 16px;
//   padding-inline-end: 16px;
//   padding-top: 16px;
//   padding-bottom: 16px;
// }

// ion-card-header {
//   &.ios {
//     padding-inline-start: 16px;
//     padding-inline-end: 16px;
//     padding-top: 16px;
//   }

//   + .card-content-ios {
//     padding-inline-start: 16px;
//     padding-inline-end: 16px;
//     padding-bottom: 16px;
//   }
// }

ion-chip {
  margin-left: 0;
}

ion-title.profile .toolbar-title {
  text-align: left !important;
}

ion-icon.profile {
  font-size: 24px;
  margin-right: 5px;
}

div.profile {
  align-items: center;
  position: relative;
}

small {
  font-size: 0.7rem;
}

span.primary {
  color: var(--ion-color-primary);
}

ul {
  margin-top: 0;
  margin-bottom: 0;
  padding-inline-start: 20px;
}

.projectCard {
  width: 100%;
}

/** iOS Specific Styles **/
.ios {
  ion-toolbar {
    ion-title.profile {
      padding-inline-start: 0;
      padding-inline-end: 0;
      text-align: left;
      font-size: 130%;
    }
  }

  small {
    font-size: 0.65em;
  }

  ion-title.title-large {
    font-size: 1.7em;
  }

  ion-card {
    ion-card-content {
      .projectCard {
        margin-top: 3px;
      }

      ion-list {
        margin-top: 3px;
      }
    }
  }

  ion-list-header ion-label {
    font-size: 0.9em;
    margin-top: 16px;
    margin-bottom: 0;
  }

  .filter {
    margin-top: 10px;
  }
}

div.spacer {
  height: 50px;
}

// @media only screen and (max-width: 320px) {
//   ion-card-title {
//     font-size: 1em;
//   }
//   small {
//     font-size: 0.6em;
//   }
//   ion-chip {
//     font-size: 0.7em;
//     padding: 10px;
//   }
// }

.fullwidth {
  width: 100%;
}

.half {
  height: 50%;
}

.custom-dropdown {
  .popover-content {
    width: 250px;
  }
}

.prevButton ion-button {
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.swiper .swiper-pagination {
  position: fixed;
  bottom: 1.5rem !important;
}

.swiper .swiper-pagination-bullet {
  background-color: var(--ion-color-primary) !important;
}

.regionOption {
  padding: 0 10px 0 10px;
}

@media screen and (min-width: 992px) {
  .hideOnLarge {
    display: none !important;
  }
  ion-tab-bar {
    display: none !important;
  }
}
